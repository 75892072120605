import { isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  productCheckout,
  useGetProducts,
} from '../../../../services/Subscription/subscriptionService';
import { useContainerContext } from '../../../../context/ContainerContext';
import useGuideHook from '../../../../services/Guide/guideService';

function SubscribeComponent(_props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: { guideId },
  } = useContainerContext();
  const { getGuideProfile } = useGuideHook({
    id: guideId,
  });
  const { data: guideDetail } = getGuideProfile;
  const { data: products, isLoading } = useGetProducts();

  const checkTds =
    !isLoading &&
    products &&
    isArray(products.products) &&
    products.products.map((p: any) => (
      <td>
        <i className="fa fa-check"></i>
      </td>
    ));

  return (
    <div>
      <h5>{t('subscribe.select.title')}</h5>
      <p className="mt-3 mb-4">{t('subscribe.select.desc')}</p>
      <main>
        <div
          className={`row row-cols-1 row-cols-md-${
            !isLoading && products && isArray(products.products) ? products.products.length + 1 : 2
          } mb-3 text-center`}
        >
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">Free</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  0 SEK<small className="text-body-secondary fw-light">/mo</small>
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>Email support</li>
                  <li>&nbsp;</li>
                </ul>
                <button
                  onClick={() => navigate('/dashboard')}
                  type="button"
                  className="w-100 btn btn-lg btn-outline-primary"
                >
                  {t('subscribe.dashboard')}
                </button>
              </div>
            </div>
          </div>
          {!isLoading &&
            products &&
            isArray(products.products) &&
            products.products.map((p: any) => (
              <div className="col">
                <div className="card mb-4 rounded-3 shadow-sm" key={p.id}>
                  <div className="card-header py-3">
                    <h4 className="my-0 fw-normal">{p.title}</h4>
                  </div>
                  <div className="card-body">
                    <h1 className="card-title pricing-card-title">
                      {p.price} {p.currency.toUpperCase()}
                      <small className="text-body-secondary fw-light">/{p.interval}</small>
                    </h1>
                    <ul className="list-unstyled mt-3 mb-4">
                      <li>{p.description}</li>
                      <li>Priority email support</li>
                    </ul>
                    {guideDetail && (
                      <button
                        className=" w-100 btn btn-lg btn-primary css style SS_ProductCheckout"
                        type="button"
                        onClick={() => productCheckout(p.id, guideDetail.guideProfile.email)}
                      >
                        {t('Subscribe.btn')}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          {/* <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm border-primary">
              <div className="card-header py-3 text-bg-primary border-primary">
                <h4 className="my-0 fw-normal">Enterprise</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  29 SEK<small className="text-body-secondary fw-light">/mo</small>
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>Phone and email support</li>
                  <li>Help center access</li>
                </ul>
                <button
                  className=" w-100 btn btn-lg btn-primary css style SS_ProductCheckout"
                  type="button"
                  data-id="1"
                  data-email="manish.ranjan@stigasoft.com"
                  data-url="http://localhost:1337"
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div> */}
        </div>

        <h2 className="display-6 text-center mb-4">{t('subscribe.compare')}</h2>

        <div className="table-responsive">
          <table className="table text-center">
            <thead>
              <tr>
                <th style={{ width: '34%' }}></th>
                <th style={{ width: '22%' }}>Free</th>
                {!isLoading &&
                  products &&
                  isArray(products.products) &&
                  products.products.map((p: any) => (
                    <th key={`prod-${p.id}`} style={{ width: '22%' }}>
                      {p.title}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" className="text-start">
                  {t('subscribe.Prioritized_Ranking')}
                </th>
                <td></td>
                {checkTds}
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  {t('subscribe.Unlimited_Trips')}
                </th>
                <td>
                  <i className="fa fa-check"></i>
                </td>
                {checkTds}
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  {t('subscribe.recieve_booking_requests')}
                </th>
                <td></td>
                {checkTds}
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  {t('subscribe.Trip_grahpics')}
                </th>
                <td></td>
                {checkTds}
              </tr>
            </tbody>

            <tbody>
              <tr>
                <th scope="row" className="text-start">
                  {t('subscribe.Priority_Email_Support')}
                </th>
                <td></td>
                {checkTds}
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  {t('subscribe.Link_to_own_website')}
                </th>
                <td></td>
                {checkTds}
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  {t('subscribe.Highlighted_in_Fishvoy_marketing_campaigns')}
                </th>
                <td></td>
                {checkTds}
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  {t('subscribe.Discount_on_Fishvoy_media_productions')}
                </th>
                <td></td>
                {checkTds}
              </tr>
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
}

export default SubscribeComponent;
