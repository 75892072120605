import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';
import {
  EmailField,
  FileSelectionField,
  NumberField,
  PhoneField,
  SelectField,
  StaticDataMultiSelectField,
  StaticDataSelectField,
  TextField,
  TimezoneField,
} from '../../components/Form';
import { ButtonSpinner } from '../../components/Spinner/ButtonSpinner';
import { Spinner } from '../../components/Spinner/Spinner';
import { useContainerContext } from '../../context/ContainerContext';
import i18n from '../../i18n';
import moment from '../../lib/moment';
import { currentApi } from '../../services/Db/dbInfo';
import { GuideProfile, guideProfileSchema } from '../../services/Guide/guide';
import useGuideHook from '../../services/Guide/guideService';
import { currencyCodes } from '../../services/currencyCodes';
import useGuideOptionsHook from '../../services/guideOptionsHook';
import { getLanguages } from '../../services/languages';
import { dirtyValues } from '../../services/utils';
import UserSubscription from './component/UserSubscription';

const Profile = () => {
  const { t } = useTranslation();
  const {
    state: { guideId },
  } = useContainerContext();

  const { useGuideTitle: guideTitles } = useGuideOptionsHook();

  const currencyCds = _.values(currencyCodes);
  let currencyTop: any = [];
  let currencySorted: any = [];
  currencyCds.forEach((c) => {
    if (
      c.code.includes('EUR') ||
      c.code.includes('SEK') ||
      c.code.includes('USD') ||
      c.code.includes('DKK') ||
      c.code.includes('GBP')
    ) {
      currencyTop.push(c);
    } else {
      currencySorted.push(c);
    }
  });
  currencyTop = currencyTop.concat(currencySorted);

  const currencyData = _.map(currencyTop, (value: any) => ({
    label: value.code,
    value: value.code,
  }));

  const languagesArray = getLanguages(i18n.language);

  const { getListing, getGuideProfile, updateGuide, updateGuideStatus } = useGuideHook({
    id: guideId,
  });
  const { data: listing, isLoading: isListingLoading } = getListing;
  const { data: guideDetail, isLoading } = getGuideProfile;
  const { mutateAsync: mutateAsyncUpdate, isLoading: isLoadingUpdate } = updateGuide;
  const { mutateAsync: mutateAsyncUpdateGuideStatus, isLoading: isLoadingUpdatStatus } =
    updateGuideStatus;

  const navigate = useNavigate();

  const methods = useForm<GuideProfile>({
    mode: 'all',
    defaultValues: {},
    resolver: yupResolver<GuideProfile>(guideProfileSchema),
  });
  const {
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields },
  } = methods;

  useEffect(() => {
    if (guideDetail) {
      reset(guideDetail.guideProfile);
    }
  }, [guideDetail, reset]);

  const onSubmit = (data: GuideProfile) => {
    if (guideId) {
      let fields = dirtyValues(dirtyFields, data);
      if (Object.keys(fields).length) {
        const variables: any = {
          id: guideId.toString(),
          guideProfile: fields,
        };

        mutateAsyncUpdate(variables, {
          onSuccess: () => {
            NotificationManager.success(t('notification.guide.saved'));
          },
        });
      }
    }
  };

  const onErrors = (data: any) => {
    console.log(data);
  };

  const onStatusChangeHandler = (id: number, status: string) => {
    const variables: any = {
      id: id,
      status: status,
    };
    mutateAsyncUpdateGuideStatus(variables, {
      onSuccess: () => {
        NotificationManager.success(t('notification.listing.statusUpdated'));
      },
    });
  };

  if (isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!guideDetail) return null;

  return (
    <div className="content">
      <div className="row">
        <div className="col-md-4">
          <div className="card card-user">
            <div className="image">
              <img
                width="100%"
                src={`${currentApi.fileBasePath}${guideDetail?.businessProfile?.businessMedia?.url}`}
                alt="..."
              />
            </div>
            <div className="card-body">
              <div className="author">
                <img
                  className="avatar border-gray"
                  src={`${currentApi.fileBasePath}${guideDetail?.guideProfile?.profilePhoto?.url}`}
                  alt="..."
                />
                <h5 className="title">{guideDetail?.businessProfile?.businessName}</h5>
              </div>
            </div>
            <div className="card-footer">
              <hr />
              <div className="button-container">
                <div className="row">
                  <div className="col-lg-6 col-md-8 col-8 ml-auto mx-auto">
                    <h5>
                      {moment(guideDetail?.businessProfile?.createdAt).format('YYYY-MM-DD')}
                      <br />
                      <small>{t('common.registration_date')}</small>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">{t('nav.myListing')}</h5>
            </div>
            <div className="card-body">
              <ul className="list-unstyled team-members">
                {isListingLoading && <ButtonSpinner />}
                {!isListingLoading &&
                  listing?.map((item: any, index: number) => {
                    return (
                      <li key={index}>
                        <div className="row">
                          <div className="col-md-9 col-9">
                            {item.label}
                            <br />

                            {item.status === 'evaluating' && (
                              <span className="text-danger">
                                <small>{t('status.avaiting_approval')}</small>
                              </span>
                            )}
                            {(item.status === 'unsent' || item.status === 'archived') && (
                              <span className="text-muted">
                                <small>{t('status.paused')}</small>
                              </span>
                            )}
                            {item.status === 'accepted' && (
                              <span className="text-success">
                                <small>{t('status.active')}</small>
                              </span>
                            )}
                          </div>
                          <div className="col-md-3 col-3 text-right">
                            {(item.status === 'unsent' || item.status === 'archived') && (
                              <Button
                                variant="outline-dark"
                                className="btn-circle-icon"
                                disabled={isLoadingUpdatStatus}
                                onClick={() => onStatusChangeHandler(item.value, 'accepted')}
                              >
                                <i className="nc-icon nc-button-pause"></i>
                              </Button>
                            )}
                            {item.status === 'accepted' && (
                              <Button
                                variant="outline-success"
                                className="btn-circle-icon"
                                disabled={isLoadingUpdatStatus}
                                onClick={() => onStatusChangeHandler(item.value, 'unsent')}
                              >
                                <i className="nc-icon nc-button-play"></i>
                              </Button>
                            )}
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
              <div className="row">
                <div className="update ml-auto mr-auto text-center">
                  <button
                    type="submit"
                    className="btn btn-gold btn-round text-uppercase"
                    onClick={() => {
                      navigate(`/guides`);
                    }}
                  >
                    {t('common.add_listing')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <UserSubscription email={guideDetail.guideProfile.email} /> 
        </div>
        <div className="col-md-8">
          <div className="card card-user">
            <div className="card-header">
              <h5 className="card-title">{t('guide.profile.edit_profile')}</h5>
            </div>
            <div className="card-body">
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit, onErrors)} noValidate>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <TextField
                        name={`firstName`}
                        placeholder={t('guide.fields.firstName.placeholder')}
                        label={t('guide.fields.firstName.label')}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <TextField
                        name={`lastName`}
                        placeholder={t('guide.fields.lastName.placeholder')}
                        label={t('guide.fields.lastName.label')}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <EmailField
                        name={`email`}
                        placeholder={t('guide.fields.email.placeholder')}
                        label={t('guide.fields.email.label')}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <PhoneField
                        name={`phoneNumber`}
                        placeholder={t('guide.fields.phoneNumber.placeholder')}
                        label={t('guide.fields.phoneNumber.label')}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <FileSelectionField
                      name={`profilePhoto`}
                      label={t('guide.fields.profilePhoto.label')}
                      selectedImagePath={'TempGuideProfile'}
                    />
                  </div>
                  <div className="mt-4">
                    <p>{t('guide.profile.additionalSettingTitle')}</p>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <SelectField
                        className="product_select"
                        name={`title`}
                        placeholder={t('guide.fields.title.placeholder')}
                        label={t('guide.fields.title.label')}
                        options={guideTitles}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <StaticDataMultiSelectField
                        className="product_select"
                        name={`languagesSpoken`}
                        placeholder={t('guide.fields.languagesSpoken.placeholder')}
                        label={t('guide.fields.languagesSpoken.label')}
                        options={languagesArray}
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="form-group col-md-6">
                      <StaticDataSelectField
                        className="product_select"
                        name={`currency`}
                        placeholder={t('guide.fields.currency.placeholder')}
                        label={t('guide.fields.currency.label')}
                        options={currencyData}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <NumberField
                        name={`experience`}
                        max={100}
                        label={t('guide.fields.experience.label')}
                        placeholder={t('guide.fields.experience.placeholder')}
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="form-group col-md-6">
                      <TimezoneField
                        className="product_select"
                        name={`timeZone`}
                        placeholder={t('guide.fields.timeZone.placeholder')}
                        label={t('guide.fields.timeZone.label')}
                      />
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="form-group col-md-12">
                      <div className="mt-2 mb-3 text-center">
                        <button
                          type="submit"
                          className="next btn btn-gold btn-round text-uppercase"
                          disabled={!isDirty || isLoadingUpdate}
                        >
                          {isLoadingUpdate ? (
                            <ButtonSpinner message={t('common.updating')} />
                          ) : (
                            t('guide.profile.update_profile')
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
