import parse from 'html-react-parser';
// import { useTranslation } from 'react-i18next';
import { ButtonSpinner } from '../../components/Spinner/ButtonSpinner';
import { useGetAboutPage } from '../../services/User/userService';
import './style.css';

const About = () => {
  // const { t } = useTranslation();
  const { data, isLoading } = useGetAboutPage();

  return (
    <div className="info-page" style={{
      paddingBottom: 20 }}>
      <div
        className="container-fluid display_operator_image"
        style={{ backgroundImage: `url(${data && data.coverPicture ? data.coverPicture.url : "/img/image.jpg"})` }}
      >
        <div className="row">
          <div className="display_operator-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center whitetext">
            <h2 className="display_operator_name">{data && data.pageTitle}</h2>
          </div>
        </div>
      </div>
      <div
        className="container"
        style={{ backgroundColor: '#FFF', marginTop: '-90px', maxWidth: '960px',
         }}
      >
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-5">
            <div className="display_operator_info mb-3">
              {isLoading && <ButtonSpinner />} {data && parse(data.content)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
