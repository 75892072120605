import {
  Conversation,
  GuideDetails,
  MasterData,
  Message,
  ModelStatus,
  PresentYourBoat,
  TripDetails,
} from '../Guide/guide';
import { User, UserDto } from '../User/user';

export const nameofFactory =
  <T>() =>
  (name: keyof T) =>
    name;

export const nameof = <T>(name: keyof T) => name;

export function toGuideDetailsDto(guideDetails: GuideDetails): GuideDetailsDto {
  let guideParams: GuideDetailsDto = {
    firstName: guideDetails.guideProfile?.firstName,
    lastName: guideDetails.guideProfile?.lastName,
    email: guideDetails.guideProfile?.email,
    phoneNumber: guideDetails.guideProfile?.phoneNumber,
    languagesSpoken: guideDetails.guideProfile?.languagesSpoken?.join(';'),
    businessName: guideDetails.businessProfile?.businessName,
    businessDescription: guideDetails.businessProfile?.businessDescription,
    businessEmail: guideDetails.businessProfile?.businessEmail,
    certificates: guideDetails.businessProfile?.certificates,
    businessPhone: guideDetails.businessProfile?.businessPhone,
    country: guideDetails.businessProfile?.country,
    streetAddress: guideDetails.businessProfile?.streetAddress,
    postalCode: guideDetails.businessProfile?.postalCode,
    currency: guideDetails.guideProfile?.currency,
    title: setOptionDataToDtoObject(guideDetails?.guideProfile?.title),
    timeZone: guideDetails.guideProfile?.timeZone,
    status: guideDetails.guideProfile?.status,
    city: guideDetails.businessProfile?.city,
    profilePhoto: guideDetails.guideProfile?.profilePhoto,
    businessMedia: guideDetails.businessProfile?.businessMedia,
    experience: guideDetails.guideProfile?.experience || 0,
  };

  return guideParams;
}
export function toMasterData(dto: MasterDataDto): MasterData {
  return {
    value: dto?.id?.toString() || dto,
    label: dto?.name || dto,
  } as MasterData;
}

export function toId(dto: any): MasterData {
  return dto.id.toString();
}

function setNestedArrayObject<T>(obj: any, operation: any) {
  if (obj && obj.data) {
    if (Array.isArray(obj.data)) {
      return obj?.data?.map((dto: T) => {
        return operation(dto);
      });
    }
  }

  if (Array.isArray(obj)) {
    return obj?.map((dto: T) => {
      return operation(dto);
    });
  }
  return [];
}

function setOptionDataToDtoObject(data?: MasterData | MasterData[]) {
  if (data) {
    if (Array.isArray(data)) {
      return data.map((item: MasterData, index) => {
        return { id: Number(item.value ? item.value : item) };
      });
    } else {
      return Number(data.id || data.value || data);
    }
  }
  return undefined;
}

function setNestedObject(obj: any, operation?: any) {
  if (obj) {
    if (obj.id) {
      if (operation) {
        return operation(obj);
      } else {
        return obj;
      }
    } else {
      if (typeof obj !== 'object') {
        return { label: obj, value: obj };
      }
    }
  }

  return undefined;
}

function setOperateDays(data: any) {
  if (data) {
    if (Array.isArray(data)) {
      return data.map((item: MasterData, index) => {
        return item.value;
      });
    }
  }
  return undefined;
}

export function toPresentYourBoat(boatDto: BoatDto): PresentYourBoat {
  return {
    id: boatDto.id,
    boatType: setNestedObject(boatDto.boatType, toMasterData),
    boatLength: boatDto.boatLength,
    boatManufacturer: boatDto.boatManufacturer,
    guestCapacity: boatDto.guestCapacity,
    wheelchairAccessible: boatDto.wheelchairAccessible,
    typeofEngine: setNestedObject(boatDto.typeofEngine, toMasterData),
    engineManufacturer: boatDto.engineManufacturer,
    numberofEngines: boatDto.numberofEngines,
    horsePowerPerEngine: boatDto.horsePowerperEngine,
    maxCruisingSpeed: boatDto.maxCruisingSpeed,
    navEquipments: setNestedArrayObject<MasterDataDto>(boatDto.navEquipments, toMasterData),
    safetyEquipments: setNestedArrayObject<MasterDataDto>(boatDto.safetyEquipments, toMasterData),
    facilities: setNestedArrayObject<MasterDataDto>(boatDto.facilities, toMasterData),
    fishingGears: setNestedArrayObject<MasterDataDto>(boatDto.fishingGears, toMasterData),
    additionalCrew: setNestedArrayObject<MasterDataDto>(boatDto.additionalCrew, toMasterData),
    extraMotor: boatDto.extraMotor,
    status: ModelStatus.UnEdited,
    boatMedia: setNestedObject(boatDto.boatMedia),
  } as PresentYourBoat;
}
export function toGuideDetails(guideDetailsDto: GuideDetailsDto): GuideDetails {
  let g: GuideDetails = {
    id: guideDetailsDto.id,
    guideProfile: {
      profilePhoto: guideDetailsDto.profilePhoto,
      title: setNestedObject(guideDetailsDto?.title, toMasterData),
      firstName: guideDetailsDto.firstName,
      lastName: guideDetailsDto.lastName,
      languagesSpoken: guideDetailsDto.languagesSpoken
        ? guideDetailsDto.languagesSpoken.split(';')
        : [],
      phoneNumber: guideDetailsDto.phoneNumber,
      email: guideDetailsDto.email,
      timeZone: guideDetailsDto.timeZone,
      currency: guideDetailsDto.currency,
      status: guideDetailsDto.status,
      experience: guideDetailsDto?.experience || 0,
    },
    businessProfile: {
      businessName: guideDetailsDto.businessName,
      businessDescription: guideDetailsDto.businessDescription,
      businessEmail: guideDetailsDto.businessEmail,
      businessPhone: guideDetailsDto.businessPhone,
      certificates: guideDetailsDto.certificates,
      country: guideDetailsDto.country,
      streetAddress: guideDetailsDto.streetAddress,
      postalCode: guideDetailsDto.postalCode,
      businessMedia: guideDetailsDto.businessMedia,
      city: guideDetailsDto.city,
      createdAt: guideDetailsDto?.createdAt,
      enableBusinessMediaUpload: guideDetailsDto.enableBusinessMediaUpload,
    },
    presentYourBoats: guideDetailsDto.guide_boats
      ? guideDetailsDto?.guide_boats?.map((boatDto: BoatDto) => {
          return toPresentYourBoat(boatDto);
        })
      : [],
    presentYourTrips:
      guideDetailsDto.guidetrips && guideDetailsDto.guidetrips
        ? guideDetailsDto.guidetrips?.map((trip: TripDto) => {
            return toTripDetails(trip);
          })
        : [],
  };
  return g;
}

export function toTripDetails(trip: TripDto): TripDetails {
  const dto: TripDetails = {
    id: trip.id,
    name: trip.name,
    type: trip.type,
    description: trip.description,
    duration: trip.duration,
    departure: trip.departure,
    isSeasonalTrip: trip.isSeasonalTrip,
    season: {
      from: trip.seasonFrom ? new Date(trip.seasonFrom) : undefined,
      to: trip.seasonTo ? new Date(trip.seasonTo) : undefined,
    },
    city: trip.city,
    maxAmountofPeople: trip?.maxAmountofPeople,
    minAmountofPeople: trip?.minAmountofPeople,
    pricePerPerson:
      trip.pricePerPerson && trip.pricePerPerson > 0
        ? {
            value: trip.pricePerPerson,
            currency: trip.priceCurrency || 'USD',
          }
        : { value: 0, currency: 'USD' },
    priceifNotShared:
      trip.priceifNotShared && trip.priceifNotShared > 0
        ? {
            value: trip.priceifNotShared,
            currency: trip.priceCurrency || 'USD',
          }
        : { value: 0, currency: 'USD' },
    targetedSpecies: setNestedArrayObject<MasterDataDto>(trip.targetedSpecies, toMasterData),
    fishingTechniques: setNestedArrayObject<MasterDataDto>(trip.fishingTechniques, toMasterData),
    fishingTypes: setNestedArrayObject<MasterDataDto>(trip.fishingTypes, toMasterData),
    isFishingLicenseIncluded: trip.isFishingLicenseIncluded,
    isNecessaryGearIncluded: trip.isNecessaryGearIncluded,
    alcoholAllowed: trip.alcoholAllowed,
    foodAndBeverages: setNestedArrayObject<MasterDataDto>(trip.foodAndBeverages, toMasterData),
    catchPolicy: setNestedArrayObject<MasterDataDto>(trip.catchPolicy, toMasterData),
    country: trip.country,
    streetAddress: trip.streetAddress,
    postalCode: trip.postalCode,
    pickupPolicy: setNestedObject(trip.pickupPolicy, toMasterData),
    cancellationPolicy: setNestedObject(trip.cancellationPolicy, toId),
    freeCancellation: trip.freeCancellation,
    guide_boat: trip?.guide_boat ? toPresentYourBoat(trip?.guide_boat) : trip?.guide_boat,
    guide: setNestedObject(trip.guide),
    minimumAge: trip.minimumAge,
    status: ModelStatus.UnEdited,
    long: trip.long,
    lat: trip.lat,
    operateDays: setNestedArrayObject<MasterDataDto>(trip.operateDays, toMasterData),
    tripMedia:
      trip.tripMedia &&
      trip.tripMedia &&
      trip.tripMedia.map((media: any) => {
        
        return media;
      }),
    isSharedTrip: trip.isSharedTrip,
    rating: trip?.rating?.average,
    multiBoatTrip: trip?.multiBoatTrip || false,
  };

  return dto;
}

export function toMasterDataDto(data: MasterData) {
  return {
    id: data,
  };
}

export function toBoatDto(boat: PresentYourBoat): BoatDto {
  let boatDto: BoatDto = {
    facilities: setOptionDataToDtoObject(boat.facilities),
    additionalCrew: setOptionDataToDtoObject(boat.additionalCrew),
    navEquipments: setOptionDataToDtoObject(boat.navEquipments),
    safetyEquipments: setOptionDataToDtoObject(boat.safetyEquipments),
    fishingGears: setOptionDataToDtoObject(boat?.fishingGears),
    boatLength: boat.boatLength,
    boatManufacturer: boat.boatManufacturer,
    boatType: setOptionDataToDtoObject(boat.boatType),
    engineManufacturer: boat.engineManufacturer,
    guestCapacity: boat.guestCapacity,
    wheelchairAccessible: boat.wheelchairAccessible,
    horsePowerperEngine: boat.horsePowerPerEngine,
    typeofEngine: setOptionDataToDtoObject(boat.typeofEngine),
    numberofEngines: boat.numberofEngines,
    maxCruisingSpeed: boat.maxCruisingSpeed,
    extraMotor: boat.extraMotor,
    id: boat.id,
    boatMedia: boat.boatMedia,
    guide: boat?.guide,
  } as BoatDto;
  return boatDto;
}
export function toTripDto(trip: TripDetails): TripDto {
  let tripDto: TripDto = {
    id: trip.id,
    name: trip.name,
    description: trip.description,
    type: trip.type,
    guide: trip.guide,
    guide_boat: setOptionDataToDtoObject(trip.guide_boat),
    duration: trip.duration,
    departure: trip.departure,
    isSeasonalTrip: trip.isSeasonalTrip,
    isSharedTrip: trip.isSharedTrip,
    pricePerPerson: trip?.pricePerPerson?.value,
    priceifNotShared: trip?.priceifNotShared?.value,
    fishingTypes: setOptionDataToDtoObject(trip.fishingTypes),
    targetedSpecies: setOptionDataToDtoObject(trip.targetedSpecies),
    fishingTechniques: setOptionDataToDtoObject(trip.fishingTechniques),
    isFishingLicenseIncluded: trip.isFishingLicenseIncluded,
    isNecessaryGearIncluded: trip.isNecessaryGearIncluded,
    alcoholAllowed: trip.alcoholAllowed,
    foodAndBeverages: setOptionDataToDtoObject(trip.foodAndBeverages),
    catchPolicy: setOptionDataToDtoObject(trip.catchPolicy),
    country: trip.country,
    streetAddress: trip.streetAddress,
    postalCode: trip.postalCode,
    pickupPolicy: setOptionDataToDtoObject(trip.pickupPolicy),
    cancellationPolicy: setOptionDataToDtoObject(trip.cancellationPolicy),
    freeCancellation: trip.freeCancellation,
    priceCurrency:
      trip?.isSharedTrip?.toString() === 'true'
        ? trip?.pricePerPerson?.currency
        : trip.priceifNotShared?.currency,
    city: trip.city,
    minimumAge: trip.minimumAge,
    lat: trip.lat,
    long: trip.long,
    tripMedia: trip.tripMedia,
    operateDays: setOperateDays(trip.operateDays),
    rating: trip.rating,
    multiBoatTrip: trip?.multiBoatTrip || false,
  };

  if (trip.isSeasonalTrip) {
    if (trip?.season?.from) {
      tripDto.seasonFrom = new Date(trip.season.from).toISOString();
    }

    if (trip?.season?.to) {
      tripDto.seasonTo = new Date(trip.season.to).toISOString();
    }
  }

  if (trip.maxAmountofPeople !== undefined) {
    tripDto.maxAmountofPeople = Number(trip.maxAmountofPeople);
  }

  if (trip.minAmountofPeople !== undefined) {
    tripDto.minAmountofPeople = Number(trip.minAmountofPeople);
  }

  if (trip?.pricePerPerson?.value) {
    tripDto.pricePerPerson = Number(trip?.pricePerPerson?.value);
  }

  if (trip?.priceifNotShared?.value) {
    tripDto.priceifNotShared = Number(trip?.priceifNotShared?.value);
  }

  return tripDto;
}

interface ConversationDto {
  id: number;
  form: string;
  buyer: any;
  seller: any;
  guidetrip: any;
  buyer_status: string;
  seller_status: string;
  last_message: any;
}

export function toConversation(conversationDto: ConversationDto): Conversation {
  const guideTrip = toTripDetails(conversationDto?.guidetrip);
  const buyer = toUser(conversationDto?.buyer);
  const seller = toUser(conversationDto?.seller);
  const message = toMessage(conversationDto?.last_message);

  return {
    id: conversationDto.id,
    seller_status: conversationDto.seller_status,
    buyer_status: conversationDto.buyer_status,
    last_message: message,
    trip: {
      id: Number(guideTrip.id),
      name: guideTrip.name,
    },
    buyer: {
      id: Number(buyer.id),
      name: buyer.username,
    },
    seller: {
      id: Number(seller.id),
      name: seller.username,
    },
    guide: {
      id: Number(guideTrip?.guide?.id),
      name: guideTrip?.guide?.businessName,
      profilePhoto: guideTrip?.guide?.profilePhoto?.url,
    },
  };
}

interface MessageDto {
  id: number;
  sent_at: string;
  message: string;
  sender: any;
}

export function toUser(userDto: UserDto): User {
  if (!userDto) return userDto;

  return userDto as User;
}

export function toMessage(messageDto: MessageDto): Message {
  if (!messageDto) return messageDto;
  return {
    id: messageDto.id,
    sent_at: messageDto.sent_at,
    message: messageDto.message,
    sender: toUser(messageDto?.sender),
  };
}
