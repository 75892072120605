import { TFunction } from 'i18next';
import * as _ from 'lodash';
import React from 'react';
import { useLocation } from 'react-router-dom';
import moment from '../lib/moment';
import { FormatMoney, currencyCodes } from '../services/currencyCodes';
import { Price, Season } from './Guide/guide';

export function isPositiveNumber(value: string | number): boolean {
  // Replace comma with dot for validation
  value = value.toString().replace(/,/g, '.');

  let num = Number(safeString(value));

  return !isNaN(num) && num >= 0;
}

export function isPositiveInteger(value: string | number): boolean {
  if (!isPositiveNumber(value)) {
    return false;
  }

  let num = Number(safeString(value));
  return Number.isInteger(num);
}

export function safeString(value: string | number | undefined): string {
  return !isEmpty(value) ? String(value) : '';
}

export function safeNumber(value: string): Number {
  return Number(safeString(value));
}

export function safeNumberValue(value: number | null | undefined): any {
  if (value === undefined || value === null) {
    return '';
  }
  return value;
}

export function isEmpty(value: string | number | undefined | boolean | null | object) {
  return (
    typeof value === 'undefined' ||
    value === null ||
    value === '' ||
    Object.keys(value).length === 0
  );
}

export function isMatchingString(value: string, toMatch: string) {
  return value === toMatch;
}

export function toStringOrNull(value: string | boolean | null | undefined) {
  if (isEmpty(value)) {
    return null;
  }

  return String(value);
}

export function toBooleanOrNull(value: string | boolean | null | undefined) {
  if (isEmpty(value)) {
    return null;
  }

  if (String(value).toLowerCase() === 'true') {
    return true;
  }

  if (String(value).toLowerCase() === 'false') {
    return false;
  }

  return null;
}

export function emailUsername(emailAddress: string) {
  if (!emailAddress) return '';
  if (emailAddress.indexOf('@') > 0) {
    return emailAddress.substring(0, emailAddress.indexOf('@'));
  }
  return emailAddress;
}

export function getEmailInitials(email: string) {
  const parts = email.split('@')[0].split('.');
  const initials = parts.map((part: any) => part[0].toUpperCase()).join('');
  return initials;
}

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'auto',
  });
};

export const getValuesFromArray = (array: string[], v: string[]) => {
  let values: string[] = [];

  for (let i = 0; i < v.length; i++) {
    const fullValue = array[Number.parseInt(v[i])];
    values.push(fullValue.slice(0, fullValue.indexOf(':')));
  }

  return values;
};
export const getValueFromArray = (array: string[], v: number) => {
  let value: string = '';

  const fullValue = array[v];
  value = fullValue.slice(0, fullValue.indexOf(':'));

  return value;
};

export function GenerateUuidv4(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c: string) {
    var r = (Math.random() * 16) | 0,
      // tslint:disable-next-line: no-bitwise
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export interface IDropDownOptions {
  label: string;
  value: string;
}

export const removeIgnoredFromArray = (array: any[]) => {
  const newArray = array.filter((element) => {
    return !element.startsWith('//');
  });

  return newArray;
};

export const getObjectFromJsonString = (
  jsonString: string
): Array<{ label: string; value: string }> => {
  let data = removeIgnoredFromArray(jsonString.split(';'));
  return _.map(data, (value: string) => ({
    label: value.split(':')[0],
    value: value.split(':')[1],
  })) as unknown as Array<{ label: string; value: string }>;
};

export const getLabelFromJsonString = (jsonString: string, id: string) => {
  let data = removeIgnoredFromArray(jsonString.split(';'));
  const obj = _.find(data, function (value: string) {
    if (value.split(':')[1] === id) {
      return true;
    }
  });
  if (obj) {
    return obj.split(':')[0];
  }
  return '';
};

export const googleTranslate = async (text: string, toLanguage: string) => {
  console.log('tolanguage', toLanguage);
  const API_KEY = [process.env.REACT_APP_GOOGLEAPI_PRIVATEKEY as string];

  let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}&q=${encodeURI(
    text
  )}&target=${toLanguage}`;

  let translationPromise = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });

  return translationPromise.json();
};

export const googleDetectLanguage = async (text: string) => {
  const API_KEY = [process.env.REACT_APP_GOOGLEAPI_PRIVATEKEY as string];

  let url = `https://translation.googleapis.com/language/translate/v2/detect?key=${API_KEY}&q=${encodeURI(
    text
  )}`;

  let translanguagePromise = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });

  return translanguagePromise.json();
};

export function isFiles(files: File[] | MediaFileDto[]): files is File[] {
  let fs = files as any[];
  for (var fllength in fs) {
    if (fllength !== 'length' && fllength !== 'item') {
      if (
        fs[fllength].type === undefined ||
        fs[fllength].size === undefined ||
        typeof fs[fllength].size !== 'number' ||
        fs[fllength].lastModified === undefined ||
        typeof fs[fllength].lastModified !== 'number'
      ) {
        return false;
      }
    }
  }

  return true;
}

export function isFile(file: File | MediaFileDto): file is File {
  let fl = file as any;
  if (
    !fl ||
    !fl.type ||
    fl.size === undefined ||
    typeof fl.size !== 'number' ||
    fl.lastModified === undefined ||
    typeof fl.lastModified !== 'number'
  ) {
    return false;
  }

  return true;
}

export function isMedias(files: File[] | MediaFileDto[]): files is MediaFileDto[] {
  let fs = files as any[];
  for (var file in fs) {
    if (
      fs[file].ext === undefined ||
      fs[file].hash === undefined ||
      typeof fs[file].size === 'undefined' ||
      fs[file].mime === undefined ||
      typeof fs[file].url === 'undefined'
    ) {
      return false;
    }
  }
  return true;
}

export function isPrice<T>(item: T | Price): item is Price {
  let testItem: any = item;

  return (
    testItem.value !== undefined &&
    testItem.currency !== undefined &&
    typeof testItem.value === 'number' &&
    typeof testItem.currency === 'string'
  );
}

export function getPrice(t: any, isSharedTrip: boolean, price?: Price, hasboat?: boolean) {
  if (!price) return '';
  const fm = new FormatMoney({
    decimals: 2,
    append: true,
    leadZeros: false,
    symbol: getCurrencyByCode(price.currency ? price.currency : ''),
  });

  if (isSharedTrip) {
    return `${fm.from(price.value || 0)} (${t('common.per_person')})`;
  } else {
    return `${fm.from(price.value || 0)} ${hasboat ?` (${t('common.per_boat')})`: ''}`;
  }
}

export function getSession(
  t: TFunction,
  operateDays: string[],
  isSeasonalTrip: boolean,
  season?: Season | null
) {
  function convertAbbreviatedDays(abbreviatedDays: string[]) {
    const daysMap: any = {
      Mon: t('days.mon'),
      Tue: t('days.tue'),
      Wed: t('days.wed'),
      Thu: t('days.thu'),
      Fri: t('days.fri'),
      Sat: t('days.sat'),
      Sun: t('days.sun'),
    };

    return abbreviatedDays
      .map((day: any) => daysMap[day.label] || day)
      ?.map((item: any) => item)
      ?.join(', ');
  }

  try {
    if (isSeasonalTrip && season && season.from && season.to) {
      return `${convertAbbreviatedDays(operateDays)} (${moment(season.from).format(
        'Do [of] MMMM'
      )} - ${moment(season.to).format('Do [of] MMMM')})`;
    }

    return `${convertAbbreviatedDays(operateDays)}`;
  } catch (error) {
    return '';
  }
}

function getCurrencyByCode(code: string) {
  return (
    _.find(currencyCodes, function (currency) {
      return currency.code === code;
    })?.symbol || '$'
  );
}

export function isGuideDetailsDto(item: string | GuideDetailsDto): item is GuideDetailsDto {
  let aKeys = Object.keys(item).sort();
  let bKeys = Object.keys(ensureGuideDetailsDto() as GuideDetailsDto).sort();
  return JSON.stringify(aKeys) === JSON.stringify(bKeys);
}

function ensureGuideDetailsDto() {
  return {
    _id: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    languagesSpoken: '',
    businessName: '',
    businessDescription: '',
    businessEmail: '',
    businessPhone: '',
    certificates: false,
    country: '',
    city: '',
    streetAddress: '',
    postalCode: '',
    currency: '',
    title: '',
    timeZone: '',
    businessMedia: {} as MediaFileDto,
    profilePhoto: {} as MediaFileDto,
    guidetrips: [{} as TripDto],
    user: '',
    status: '',
  };
}

export function useRouteQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const dirtyValues = (dirtyFields: any | boolean, allValues: any): any => {
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }
  function removeEmptyValues(input: any): any {
    function isEmptyObject(obj: object): boolean {
      if (typeof obj !== 'object') {
        return false;
      }
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    }

    function filterRecursively(item: any): any {
      if (typeof item === 'object') {
        if (Array.isArray(item)) {
          return item
            .map((value) => filterRecursively(value))
            .filter((value) => !isEmptyObject(value));
        } else {
          const filteredItem: { [key: string]: any } = {};
          for (const key in item) {
            const value = filterRecursively(item[key]);
            if (!isEmptyObject(value)) {
              filteredItem[key] = value;
            }
          }
          return filteredItem;
        }
      }
      return item;
    }

    return filterRecursively(input);
  }

  return removeEmptyValues(
    Object.fromEntries(
      Object.keys(dirtyFields).map((key) => [key, dirtyValues(dirtyFields[key], allValues[key])])
    )
  );
};

export function arrayMoveMutable(array: any[], fromIndex: number, toIndex: number) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

export function arrayMoveImmutable(array: any, fromIndex: number, toIndex: number) {
  const newArray = [...array];
  arrayMoveMutable(newArray, fromIndex, toIndex);
  return newArray;
}

export const preloadImage = (src: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = resolve;
    img.onerror = reject;
  });
};
